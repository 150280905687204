.deckgl-container {
    position: relative;
    z-index: 0; /* DeckGLコンテナの基準レベル */
}

.maplibre-map-container {
    position: absolute;
    z-index: 1; /* DeckGLコンテナより上 */
}

.popup-container {
    position: absolute;
    z-index: 1001; /* 全ての上に表示されるように設定 */
}
